<template>
<i :class="`icon icon-${name}`"></i>
</template>

<style scoped>
.icon {
    display: inline-block;
    background-size: 100%;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin: 0px 5px;
    z-index: 9999999;
}

.icon:hover {
    background-color: #e8ecec;
    transition: all 0.5s ease;
}

.icon-back {
    background-image: url(../../assets/icons/back.png);
    width: 30px;
}
.icon-back_light {
    background-image: url(../../assets/icons/back_light.png);
    width: 30px;
    height: 16px;
}

.icon-home {
    background-image: url(../../assets/icons/home.png);
}
.icon-home_dark {
    background-image: url(../../assets/icons/home_dark.png);
}

.icon-statistic {
    background-image: url(../../assets/icons/statistic.png);
    width: 20px;
    height: 17px;
}

.icon-tags_filter {
    background-image: url(../../assets/icons/tags_light.png);
    width: 18px;
    height: 18px;
}

.icon-archive {
    background-image: url(../../assets/icons/archive.png);
    width: 21px;
}
.icon-archive_light {
    background-image: url(../../assets/icons/archive_light.png);
    width: 19px;
    height: 18px;
}

.icon-restore {
    background-image: url(../../assets/icons/restore.png);
    height: 22px;
}
.icon-restore_light {
    background-image: url(../../assets/icons/restore_light.png);
    height: 22px;
}

.icon-present {
    background-image: url(../../assets/icons/present.png);
    width: 22px;
    height: 23px;
}

.icon-next {
    background-image: url(../../assets/icons/next.png);
    height: 16px;
    width: 10px;
}

.icon-prev {
    background-image: url(../../assets/icons/prev.png);
    height: 20px;
    width: 12px;
}

.icon-refresh {
    background-image: url(../../assets/icons/refresh.png);
}
.icon-refresh_light {
    background-image: url(../../assets/icons/refresh_light.png);
}

.icon-reply {
    background-image: url(../../assets/icons/reply.png);
}

.icon-search {
    background-image: url(../../assets/icons/search.png);
    margin: 5px;
}
.icon-search_light {
    background-image: url(../../assets/icons/search_light.png);
}

.icon-inbox {
    background-image: url(../../assets/icons/inbox.png);
    width: 22px;
    height: 17px;
}

.icon-inbox_en {
    background-image: url(../../assets/icons/inbox_en.png);
    width: 22px;
    height: 17px;
}

.icon-inbox_payers {
    background-image: url(../../assets/icons/inbox_payers.png);
    width: 22px;
    height: 17px;
}

.icon-sent {
    background-image: url(../../assets/icons/sent.png);
    width: 22px;
    height: 15px;
}

.icon-spam {
    background-image: url(../../assets/icons/spam.png);
    width: 17px;
    height: 19px;
}

.icon-star {
    background-image: url(../../assets/icons/star_grey.png);
    height: 19px;
}

.icon-star-on {
    background-image: url(../../assets/icons/star_on.png);
    height: 19px;
}

.icon-star-off {
    background-image: url(../../assets/icons/star_off.png);
}
.icon-star-off_light {
    background-image: url(../../assets/icons/star_off_light.png);
}

.icon-mark-read {
    background-image: url(../../assets/icons/mark_read.png);
    height: 23px;
}
.icon-mark-read_light {
    background-image: url(../../assets/icons/mark_read_light.png);
    height: 23px;
}

.icon-mark-unread {
    background-image: url(../../assets/icons/mark_unread.png);
    height: 23px;
}
.icon-mark-unread_light {
    background-image: url(../../assets/icons/mark_unread_light.png);
    height: 23px;
}

.icon-payer {
    background-image: url(../../assets/icons/payer.png);
    height: 20px;
    width: 10px;
}

.icon-down {
    background-image: url(../../assets/icons/down.png);
    width: 13px;
    height: 13px;
    margin-bottom: 6px;
    margin-right: -2px;
}

.icon-right {
    background-image: url(../../assets/icons/right.png);
    width: 13px;
    height: 13px;
    margin-bottom: 6px;
    margin-right: -2px;
}
.icon-right_light {
    background-image: url(../../assets/icons/right_light.png);
    width: 13px;
    height: 13px;
    margin-bottom: 6px;
    margin-right: -2px;
}

.icon-music-off {
    background-image: url(../../assets/icons/music_off.png);
    width: 25px;
    height: 25px;
}
.icon-music-on {
    background-image: url(../../assets/icons/music_on.png);
    width: 22px;
    height: 25px;
}
.icon-sound-on {
    background-image: url(../../assets/icons/sound_on.png);
    width: 23px;
    height: 22px;
}
.icon-sound-off {
    background-image: url(../../assets/icons/sound_off.png);
    width: 23px;
    height: 23px;
}

.icon-android {
    background-image: url(../../assets/sources/androidsocial.png);
}
.icon-ios {
    width: 17px;
    height: 21px;
    background-image: url(../../assets/sources/applesocial.png);
}
.icon-draugiem {
    background-image: url(../../assets/sources/draugiem.png);
}
.icon-facebook {
    background-image: url(../../assets/sources/facebook.png);
}
.icon-fotostrana {
    background-image: url(../../assets/sources/fotostrana.png);
}
.icon-mbga {
    background-image: url(../../assets/sources/mbga.png);
}
.icon-sp_mbga {
    background-image: url(../../assets/sources/sp_mbga.png);
}
.icon-mm {
    background-image: url(../../assets/sources/mm.png);
}
.icon-ok {
    background-image: url(../../assets/sources/ok.png);
}
.icon-vk {
    background-image: url(../../assets/sources/vk.png);
}

.icon-samsung {
  background-image: url(../../assets/sources/samsung.png);
}

.icon-ton {
  background-image: url(../../assets/sources/ton.png);
}

.icon-instant {
    background-image: url(../../assets/sources/instant.png);
    height: 18px;
    width: 19px;
}
.icon-microsoft {
    background-image: url(../../assets/sources/microsoft.png);
}
.icon-amazon {
    background-image: url(../../assets/sources/web_amazon.png);
}
.icon-xsolla {
    background-image: url(../../assets/sources/xsolla.png);
}
.icon-discord {
    background-image: url(../../assets/sources/discord.png);
}

.icon-gdcom {
    background-image: url(../../assets/sources/gdcom.png);
}
.icon-plinga {
    background-image: url(../../assets/sources/plinga.png);
}
.icon-yandex {
    background-image: url(../../assets/sources/yandex.png);
    width: 18px;
    height: 18px;
}
.icon-iyodo1 {
    background-image: url(../../assets/sources/yodo.png);
    width: 18px;
    height: 18px;
}
.icon-macos {
    background-image: url(../../assets/sources/macos.png);
}
.icon-kongregate {
  background-image: url(../../assets/sources/kongregate.png);
}
.icon-xiaomi {
  background-image: url(../../assets/sources/mi.png);
}
.icon-msstart {
  background-image: url(../../assets/sources/msn.png);
}

.icon-rustore {
    background-image: url(../../assets/sources/rustore.png);
}

.icon-mygames {
  background-image: url(../../assets/sources/mygames.png);
}

.icon-crazy {
    background-image: url(../../assets/sources/crazy.png);
}

.icon-cleverapps {
    background-image: url(../../assets/sources/cleverapps.png);
}

.icon-gesturecard {
    background-image: url(../../assets/gestures/gesturecard.png);
    width: 152px;
    height: 153px;
}
.icon-gesturecard:hover {
    background-color: transparent !important;
    transition: none;
    border-radius: unset;
}
.icon-postpone {
    background-image: url(../../assets/gestures/postopne.png);
    width: 119px;
    height: 119px;
}
.icon-postpone_active {
    background-image: url(../../assets/gestures/postopne_active.png);
    width: 119px;
    height: 119px;
}
.icon-postpone_active:hover {
    background-color: transparent !important;
    transition: none;
    border-radius: unset;
}
.icon-resolve {
    background-image: url(../../assets/gestures/resolve.png);
    width: 119px;
    height: 119px;
}
.icon-resolve_active {
    background-image: url(../../assets/gestures/resolve_active.png);
    width: 119px;
    height: 119px;
}
.icon-resolve_active:hover {
    background-color: transparent !important;
    transition: none;
    border-radius: unset;
}
.icon-skip {
    background-image: url(../../assets/gestures/skip.png);
    width: 119px;
    height: 119px;
}
.icon-skip_active {
    background-image: url(../../assets/gestures/skip_active.png);
    width: 119px;
    height: 119px;
}
.icon-skip_active:hover {
    background-color: transparent !important;
    transition: none;
    border-radius: unset;
}
.icon-default_avatar {
    background-image: url(../../assets/default_avatar.jpg);
    width: 50px;
    height: 50px;
}

.icon-payment_error {
  background-image: url(../../assets/payment/error.png);
  width: 25px;
  height: 25px;
}
.icon-payment_new {
  background-image: url(../../assets/payment/new.png);
  width: 25px;
  height: 25px;
}
.icon-payment_restore {
  background-image: url(../../assets/payment/restore.png);
  width: 25px;
  height: 25px;
}
.icon-payment_success {
  background-image: url(../../assets/payment/success.png);
  width: 25px;
  height: 25px;
}
.icon-payment_refund {
    background-image: url(../../assets/payment/refund.png);
    width: 25px;
    height: 25px;
}
.icon-payment_duplicate {
  background-image: url(../../assets/payment/duplicate.png);
  width: 25px;
  height: 25px;
}

.icon-price_kred {
    background-image: url(../../assets/payment/kred.png);
    width: 25px;
    height: 25px;
}
.icon-price_ok {
    background-image: url(../../assets/payment/ok.png);
    width: 25px;
    height: 25px;
}
.icon-price_yandex {
    background-image: url(../../assets/payment/yandex.png);
    width: 25px;
    height: 25px;
}

.icon-adventure {background-image: url(../../assets/games/adventure.png);}
.icon-crocword {background-image: url(../../assets/games/crocword.png);}
.icon-differences {background-image: url(../../assets/games/differences.png);}
.icon-heroes {background-image: url(../../assets/games/heroes.png);}
.icon-magicwords {background-image: url(../../assets/games/magicwords.png);}
.icon-mergecraft {background-image: url(../../assets/games/mergecraft.png);}
.icon-olympics {background-image: url(../../assets/games/olympics.png);}
.icon-riddles {background-image: url(../../assets/games/riddles.png);}
.icon-runes {background-image: url(../../assets/games/runes.png);}
.icon-scramble {background-image: url(../../assets/games/scramble.png);}
.icon-tripeaks {background-image: url(../../assets/games/tripeaks.png);}
.icon-wondermerge {background-image: url(../../assets/games/wondermerge.png);}
.icon-wordsoup {background-image: url(../../assets/games/wordsoup.png);}
.icon-fairy {background-image: url(../../assets/games/fairy.png);}
.icon-spades {background-image: url(../../assets/games/spades.png);}
.icon-woodenblock {background-image: url(../../assets/games/woodenblock.png);}
.icon-tetris {background-image: url(../../assets/games/tetris.png);}
.icon-zenmatch {background-image: url(../../assets/games/zenmatch.png);}
.icon-tilehero {background-image: url(../../assets/games/tilehero.png);}
.icon-hustlemerge {background-image: url(../../assets/games/hustlemerge.png);}
</style>

<script>
export default {
  name: 'icon',
  props: ['name']
}
</script>
