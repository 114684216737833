/**
* Created by Andrey Popov on 12/9/20.
*/

<template>
    <CardMenu ref="cardMenu"/>
    <Gesture ref="gesture"/>

    <div class="after-sidebar" id="gestureTarget" :style="{ opacity: cardOpacity }">
        <perfect-scrollbar class="tabs-container" :style="{'height': profileCollapsed ? '0' : '34%'}">
            <div v-if="currentTab === 'profile'">
                <div class="profile-block">
                    <HeaderInfo/>
                    <div>
                        <a v-if="userCard.hasServerLogs" :href="serverLogsLink" class="action-button" target="_blank">Server logs</a>
                        <a v-if="userCard.hasLogErrors" class="action-button redButton" :href="logsLink" target="_blank">Client logs</a>
                        <Properties :properties=profileProperties :isTable="false"/>
                    </div>
                    <div>
                        <Properties :properties=mergedPaymentsProperties />
                    </div>
                </div>
            </div>
            <div class="game-info" v-if="currentTab === 'gameInfo'">
                <Properties :properties=gameInfoProperties />
            </div>
            <div class="game-info" v-if="currentTab === 'expeditionInfo'">
                <Properties :properties=expeditionProperties />
            </div>
            <div v-if="currentTab === 'subscriptions'">
                <p class="table-header-title">Subscriptions purchases properties</p>
                <Properties :properties=subscriptionsPurchasesProperties />
                <p class="table-header-title">Subscriptions purchases</p>
                <Properties :properties=subscriptionsProperties />
            </div>
        </perfect-scrollbar>
        <tabs :tabs="tabs" :currentTab="currentTab" @onClick="handleTabClick">
            <template v-slot:extra-items>
                <DropDownButton>
                    <button class="dropdown-button">Gifts</button>
                    <template v-slot:dropdown>
                        <div v-for="giftItem in giftItems" @click="giftItem.action()" class="dropdown-item">
                            {{ giftItem.title }}
                        </div>
                    </template>
                </DropDownButton>
                <DropDownButton>
                    <button class="dropdown-button">Actions</button>
                    <template v-slot:dropdown>
                        <div v-for="action in actionItems" @click="action.action()" class="dropdown-item">
                            <Icon v-if="action.icon" :name="action.icon"/>
                            {{ action.title }}
                        </div>
                    </template>
                </DropDownButton>
                <Icon v-if="!profileCollapsed" class="collapse-icon" title="collapse" name="next"
                      @click="toggleProfile(true)"/>
                <Icon v-if="profileCollapsed" class="expand-icon" title="expand" name="next"
                      @click="toggleProfile(false)"/>
                <DropDown class="tag-selection" :options="availableTags" :hasEditTool="true" :hasDeleteTool="true"
                          v-on:selected="tagSelected" placeholder='Choose tag or enter new'>>
                </DropDown>
                <div v-if="tags" class="tags">
                    <Label class="tag" :colorBg="true" :closeCallback="removeTag" :label="tag"
                           v-for="tag in tags"></Label>
                </div>
            </template>
        </tabs>

        <chat ref="chat"/>
    </div>

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></loading>

</template>

<script>

import CardMenu from "@/components/usercard/cardmenu.vue";
import Gesture from "@/components/usercard/gesture.vue";
import HeaderInfo from "@/components/usercard/headerinfo.vue";
import Tabs from '@/controls/tabs.vue';
import Icon from '@/controls/icon.vue';
import Properties from '@/controls/properties.vue';
import Chat from "@/components/chat/chat.vue";
import Label from '@/controls/label.vue';
import DropDown from '@/controls/dropdown';
import DropDownButton from '@/controls/dropdownbutton';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue3-circle-progress/dist/circle-progress.css";

import utils from "@/utils.js";
import config from "@/config.js";

export default {
    name: 'UserCard',
    components: {
        HeaderInfo,
        CardMenu,
        Gesture,
        Chat,
        Tabs,
        Label,
        Icon,
        Properties,
        Loading,
        DropDown,
        DropDownButton
    },
    data() {
        return {
            currentTab: 'profile',
        };
    },
    beforeCreate() {
        if (!this.$store.getters.isAuthenticated) {
            this.$router.push({name: 'Login'});
        }
    },
    created() {
        if (this.$route.params.userId) {
            this.$store.dispatch('fetchUserCard', {
                userId: this.$route.params.userId
            });
        }

        if (!this.$store.state.tags.all) {
            this.$store.dispatch('fetchTags');
        }
        this.$store.dispatch('fetchUnreadCount');

        if (this.zenActive) {
            this.$store.dispatch('fetchZenTotalCount', this.$route.params.folder.split(':')[1]);
        }
    },
    computed: {
        isLoading() {
            return this.$store.state.loading;
        },
        userCard() {
            return this.$store.state.userCard ? this.$store.state.userCard : {};
        },
        cardOpacity() {
            return this.$refs.gesture && this.$refs.gesture.opacity;
        },
        userId() {
            return this.$route.params.userId;
        },
        profileCollapsed() {
            return this.$store.state.uiState.userProfileCollapsed;
        },
        zenActive() {
            return this.$store.state.zen && this.$store.state.zen.active && this.$store.state.zen.userIds.indexOf(this.$route.params.userId) !== -1;
        },
        tags() {
            if (!this.userCard.tags) {
                return [];
            }

            return this.userCard.tags.filter(function (tag) {
                return tag.dialogueId == this.userCard.dialogueId;
            }.bind(this)).map(function (tag) {
                return tag.tag;
            });
        },
        availableTags() {
            return this.$store.state.tags.all;
        },
        logsLink() {
            var gameUrl = config.games.filter(function (game) {
                return game.name === this.$route.params.folder.split(':')[1];
            }.bind(this))[0].url;
            return gameUrl + "/chat/grepclientlogs/" + encodeURIComponent(this.$route.params.userId);
        },
        serverLogsLink() {
            var gameUrl = config.games.filter(function (game) {
                return game.name === this.$route.params.folder.split(':')[1];
            }.bind(this))[0].url;
            return gameUrl + "/chat/grepserverlogs/" + encodeURIComponent(this.$route.params.userId);
        },
        tabs() {
            return config.infoTabs;
        },
        gameName() {
            return this.$route.params.folder.split(':')[1];
        },
        profilePropertiesNew: function () {
            return [
                {name: 'ID', path: 'id', type: 'id'},
                {name: 'Ids history', path: 'userIdsHistory'},
                {name: 'Version', path: 'appversion'},
                {name: 'Country', path: 'country',  type: 'country'},
                {name: 'Channel', path: 'channel'},
                {name: 'Registered', path: 'registered', type: 'date'},
                {name: 'Visited', path: 'visited', type: 'date'},
                // {name: 'Session', path: 'session'},
                {name: 'Width', path: 'screenWidth'},
                {name: 'Height', path: 'screenHeight'},
                // {name: 'Off', path: 'off'},
                {name: 'Cheater', path: 'cheater'},
                {name: 'uaName', path: 'uaName'},
                {name: 'uaVersion', path: 'uaVersion'},
                {name: 'NoAds', path: 'noAds'}
            ]
                .filter((prop) => utils.hasPropValues(this.userCard, prop))
                .map((prop) => {
                    return {
                        header: prop.name,
                        type: prop.type,
                        values: utils.getPropValues(this.userCard, prop)
                    }
                });
        },
        gameInfoPropertiesNew() {
            var grouped = [
                {name: 'Gold', path: 'gold'},
                {name: 'Coins', path: 'soft'},
                {name: 'Exp', path: 'exp'}]
                .filter((prop) => utils.hasPropValues(this.userCard, prop))
                .reduce((result, prop) => {
                    result.name.push(prop.name);
                    var value = utils.getGameInfoPropValues(this.userCard, prop);
                    if (Array.isArray(value)) {
                        value = value.join(",");
                    }
                    result.value.push(value);
                    return result;
                }, {
                    name: [],
                    value: []
                });

            return Object.keys(grouped).map(key => {
                return {
                    header: key,
                    values: grouped[key]
                }
            });
        },
        profileProperties: function () {
            return [
                {name: 'ID', collection: 'users', path: 'id', type: 'id'},
                {name: 'Ids history', collection: 'useridshistory', path: 'data', type: 'useridshistory'},
                {name: 'Version', collection: 'userappversion', path: 'appversion'},
                {name: 'Country', collection: 'users', path: 'country',  type: 'country'},
                {name: 'Channel', collection: 'users', path: 'channel'},
                {name: 'Registered', collection: 'users', path: 'registered', type: 'date'},
                {name: 'Visited', collection: 'users', path: 'visited', type: 'date'},
                {name: 'Session', collection: 'sessions', path: 'session'},
                {name: 'Size', collection: 'resolutions', type: 'size'},
                {name: 'Off', collection: 'users', path: 'off'},
                {name: 'Cheater', collection: 'users', path: 'cheater'},
                {name: 'UserAgent', collection: 'useragents', type: 'agent'},
                {name: 'Bonus Level', collection: 'bonusGames', path: 'level'},
                {name: 'Starter Pack', collection: 'packs', path: 'used'},
                {name: 'NoAds', collection: 'noAds', path: 'state'},

            ]
                .filter((prop) => utils.hasPropValues(this.userCard, prop))
                .map((prop) => {
                    return {
                        header: prop.name,
                        type: prop.type,
                        values: utils.getPropValues(this.userCard, prop)
                    }
                });
        },
        gameInfoProperties() {
            var grouped = [
                {name: 'Gold', collection: 'users', path: 'gold'},
                {name: 'Coins', collection: 'users', path: 'soft'},
                {name: 'Exp', collection: 'users', path: 'exp'},
                {name: 'Lives', collection: 'lives', path: 'data'},
                {name: 'Unlim Lives', collection: 'tempgoods', path: 'data'},
                {name: 'Boosters', collection: 'boosters', path: 'data'},
                {name: 'Bonuses', collection: 'bonuses', path: 'event', type: 'date'},
                {name: 'Heroes', collection: 'heroes', path: 'data'},
                {name: 'Metha', collection: 'metha', path: 'data'},
                {name: 'Growth Fund', collection: 'growthfund', path: 'received'},
                {name: 'Units Library', collection: 'unitsLibrary', path: 'data'},
                {name: 'Units Shop', collection: 'unitsShop', path: 'data'},
                {name: 'Quests', collection: 'quests', path: 'data'},
                {name: 'Workers', collection: 'workers', path: 'data'},
                {name: 'Harvested', collection: 'harvested', path: 'data'},
                {name: 'Fogs', collection: 'fogs', path: 'data'},
                {name: 'Games', collection: 'games', path: 'data'},
                {name: 'Expedition', collection: 'games1', path: 'data'},
                {name: 'PaymentsHistory', collection: 'paymentshistory', path: 'data'}]
                .filter((prop) => utils.hasPropValues(this.userCard, prop))
                .reduce((result, prop) => {
                    result.name.push(prop.name);
                    var value = utils.getGameInfoPropValues(this.userCard, prop);
                    if (Array.isArray(value)) {
                        value = value.join(",");
                    }
                    result.value.push(value);
                    return result;
                }, {
                    name: [],
                    value: []
                });

            return Object.keys(grouped).map(key => {
                return {
                    header: key,
                    values: grouped[key]
                }
            });
        },
        subscriptionsPurchasesProperties() {
            return [
                {name: 'ID', collection: 'statSubscriptions', path: 'id'},
                {name: 'Episode', collection: 'statSubscriptions', path: 'episode'},
                {name: 'Level', collection: 'statSubscriptions', path: 'level'},
                {name: 'Item', collection: 'statSubscriptions', path: 'itemId'},
                {name: 'Price', collection: 'statSubscriptions', path: 'textPrice'},
                {name: 'Source', collection: 'statSubscriptions', path: 'source'},
                {name: 'Date', collection: 'statSubscriptions', path: 'event', type: 'date'}]
                .filter((prop) => utils.hasPropValues(this.userCard, prop))
                .map((prop) => {
                    return {
                        header: prop.name,
                        type: prop.type,
                        values: utils.getPropValues(this.userCard, prop)
                    }
                });
        },
        subscriptionsProperties() {
            if (utils.hasPropValues(this.userCard, {collection: 'subscriptions', path: 'data'})) {
                var result = ['ID', 'Source', 'Last reward time', 'Last reload time', 'Trial bought time', 'Active', 'Action']
                    .map((prop) => {
                        return {
                            header: prop,
                            values: []
                        }
                    });

                var subs = this.userCard.subscriptions;
                for (var i = 0; i < subs.length; i++) {
                    if (subs[i].data !== undefined) {
                        var data = JSON.parse(subs[i].data);
                        for (var t in data) {
                            result[0].values.push(data[t].id || "");
                            result[1].values.push(t);
                            result[2].values.push(data[t].lastRewardTime > 0 ? data[t].lastRewardTime : data[t].rewardTime > 0 ? data[t].rewardTime : '');
                            result[2].type = 'date'
                            result[3].values.push(data[t].lastReloadTime > 0 ? data[t].lastReloadTime : '');
                            result[3].type = 'date';
                            result[4].values.push(data[t].trialBoughtTime > 0 ? data[t].trialBoughtTime : '');
                            result[4].type = 'date';
                            result[5].values.push(data[t].active ? "Yes" : "No");
                            if (this.userCard.users[0].source === "android" && data[t].id) {
                                result[6].values.push({
                                    actions: [
                                        {
                                            caption: 'Cancel',
                                            action: this.refund.bind(this, 'subscription', data[t].id, 1, 'androidSubscriptions')
                                        },
                                        {
                                            caption: 'Refund',
                                            action: this.refund.bind(this, 'subscription', data[t].id, undefined, 'androidSubscriptions')
                                        }]
                                });
                            } else {
                                result[6].values.push('');
                            }
                        }
                    }
                }
                return result;
            }

            return [];
        },
        mergedPaymentsProperties() {
            return [
                {name: 'Status', collection: 'mergedPayments', path: 'state'},
                {name: 'ProductId', collection: 'mergedPayments', path: 'productId'},
                {name: 'PaymentId', collection: 'mergedPayments', path: 'paymentId'},
                {name: 'Price', collection: 'mergedPayments', path: 'price'},
                {name: 'Local price', collection: 'mergedPayments', path: 'textPrice'},
                {name: 'Date', collection: 'mergedPayments', path: 'event', type: 'date'},
                {name: 'Interval', collection: 'mergedPayments', path: 'interval'},
                {name: 'Action', collection: 'mergedPayments', isAction: true}]
                .filter((prop) => utils.hasPropValues(this.userCard, prop))
                .map((prop) => {
                    var propValues;
                    var data = this.userCard[prop.collection] || [];

                    if (prop.isAction) {
                      propValues = data.map((payment) => {
                        if (!payment.refundId) {
                          return '';
                        }

                        var actions = [];
                        if (['subsweek', 'subsmonth'].indexOf(payment.productId) !== -1) {
                          actions.push({
                            caption: 'Cancel',
                            action: this.refund.bind(this, 'subscription', payment.refundId, 1)
                          });
                        }
                        actions.push({caption: 'Refund', action: this.refund.bind(this, 'payment', payment.refundId)});
                        return {actions: actions};
                      });
                    } else if (prop.name === 'Status') {
                        propValues = data.map((payment) => {
                            return payment.state ? {icon: `payment_${payment.state}`, iconTitle: payment.stateTitle } : "";
                        });
                    } else if (prop.name === 'Local price') {
                        propValues = data.map((payment) => {
                            if (!payment.textPrice) {
                                return "";
                            }
                            if (payment.textPrice.indexOf('**') !== -1) {
                                return {
                                    icon: "price_ok",
                                    iconValue: payment.textPrice.replace("**", "")
                                }
                            } else if (payment.textPrice.indexOf('&&') !== -1) {
                                return {
                                    icon: "price_yandex",
                                    iconValue: payment.textPrice.replace("&&", "")
                                }
                            } else if (payment.textPrice.indexOf('{{') !== -1) {
                                return {
                                    icon: "price_kred",
                                    iconValue: payment.textPrice.replace("{{", "")
                                }
                            }
                            return payment.textPrice;
                        });
                    } else {
                      propValues = utils.getPropValues(this.userCard, prop);
                    }

                    return {
                        header: prop.name,
                        type: prop.type,
                        values: propValues
                    }
                })
                .filter((prop) => prop.header !== 'Action' || prop.values.find(v => v));
        },
        messageManager() {
            return this.$refs.chat.$refs.messageManager;
        },
        giftItems: function () {
            var presents = [{
                title: 'Make present',
                action: this.messageManager.showPresentModal.bind(this)
            }];

            var gameName = this.gameName.split('_')[0].toLowerCase()
            if (utils.isMerge(gameName)) {
                utils.listExpeditions(gameName).forEach(function (expedition) {
                    var title = 'Add ' + expedition + ' unit';

                    if (expedition === 'main') {
                        title = 'Add unit';
                    }

                    presents.push({
                        title: title,
                        action: this.messageManager.showAddUnitModal.bind(this, expedition)
                    })
                }.bind(this))

                presents.push({
                    title: 'Fix reset',
                    action: this.messageManager.showFixResetModal.bind(this)
                });
                presents.push({
                    title: 'Add harvested',
                    action: this.messageManager.showHarvestedModal.bind(this)
                });
            } else {
                presents.push({
                    title: 'Set progress',
                    action: this.messageManager.showProgressModal.bind(this)
                });
            }
            if (utils.isMatch3(gameName)) {
                presents.push({
                    title: 'Upgrade heroes',
                    action: this.messageManager.showHeroesModal.bind(this)
                });
            }
            if (utils.hasSimpleMetha(gameName) || utils.hasFarmMetha(gameName) || utils.hasHomeMetha(gameName)) {
                presents.push({
                    title: 'Set metha',
                    action: this.messageManager.showMethaProgressModal.bind(this)
                });
            }

            presents.push({
              title: 'Copy progress',
              action: this.messageManager.showRestoreProgressModal.bind(this)
            });

            return presents;
        },
        actionItems: function () {
            var game = this.$route.params.folder.split(':')[1];
            var dialogueIds = [utils.createDialogueId(game, this.$store.state.dialogueId)];
            var dialogueMessages = (this.userCard.messages || []).filter((message) => {
                return message.dialogueId == this.$store.state.dialogueId;
            });
            var actions = [];
            if (dialogueMessages.find(message => message.starred === 1)) {
                actions.push({
                    title: 'Remove star',
                    icon: 'star-on',
                    action: function () {
                        this.$store.dispatch('unmark', {dialogueIds: dialogueIds, userId: this.$route.params.userId});
                        this.$router.push({
                            name: 'UserCard',
                            params: {folder: "Inbox:" + game, userId: this.$route.params.userId}
                        });
                    }.bind(this)
                });
            }

            if (dialogueMessages.find(message => message.starred !== 1)) {
                actions.push({
                    title: 'Add star',
                    icon: 'star-off',
                    action: function () {
                        this.$store.dispatch('mark', {dialogueIds: dialogueIds, userId: this.$route.params.userId});
                        this.$router.push({
                            name: 'UserCard',
                            params: {folder: "Starred:" + game, userId: this.$route.params.userId}
                        });
                    }.bind(this)
                });
            }

            if (!this.zenActive) {
                if (dialogueMessages.find(message => message.isResponse === 0 && message.readByUser === 1)) {
                    actions.push({
                        title: 'Mark as unread',
                        icon: 'mark-unread',
                        action: function () {
                            this.$store.dispatch('markAsUnread', {
                                dialogueIds: dialogueIds,
                                userId: this.$route.params.userId
                            });
                        }.bind(this)
                    });
                }
                if (dialogueMessages.find(message => message.isResponse === 0 && message.readByUser === 0)) {
                    actions.push({
                        title: 'Mark as read',
                        icon: 'mark-read',
                        action: function () {
                            this.$store.dispatch('markAsRead', {
                                dialogueIds: dialogueIds,
                                userId: this.$route.params.userId
                            });
                        }.bind(this)
                    });
                }
            }

            actions = actions.concat([{
                title: 'Remove user',
                icon: 'spam',
                action: function () {
                    if (confirm("Are you sure that you want to delete user " + this.$route.params.userId + "?")) {
                        this.$store.dispatch('removeUser', this.$route.params.userId);
                    }
                }.bind(this)
            }, {
                title: 'Remove from leaderboards',
                action: function () {
                    if (confirm("Are you sure that you want remove user " + this.$route.params.userId + " from leader board?")) {
                        this.$store.dispatch('addToBlackList', this.$route.params.userId);
                    }
                }.bind(this)
            }, {
                title: 'Set cheater',
                action: function () {
                    if (confirm("Are you sure that you want mark user " + this.$route.params.userId + " as cheater? (Remove from daily cup)")) {
                        this.$store.dispatch('setCheater', this.$route.params.userId);
                    }
                }.bind(this)
            }]);

            if (this.$route.params.folder.split(':')[0] === 'Spam') {
                actions.push({
                    title: 'Not spammer',
                    action: function () {
                        this.$store.dispatch('setSpammer', {id: this.$route.params.userId, value: 0});
                    }.bind(this)
                });
            } else {
                actions.push({
                    title: 'Set spammer',
                    action: function () {
                        if (confirm("Are you sure that you want mark user " + this.$route.params.userId + " as spammer?")) {
                            this.$store.dispatch('setSpammer', {id: this.$route.params.userId, value: 1});
                        }
                    }.bind(this)
                });
            }

            actions.push({
                title: 'Toggle console stream',
                action: function () {
                    if (this.messageManager.consoleStreamEnabled) {
                        this.messageManager.dispatchMessage("Disable console stream", 'stream_off');
                    } else {
                        this.messageManager.dispatchMessage("Enable console stream", 'stream_on');
                    }
                }.bind(this)
            });

            return actions;
        }
    },
    methods: {
        handleTabClick(newTab) {
            this.toggleProfile(false);
            this.currentTab = newTab;
        },
        refund(name, id, cancel, infoTable) {
            if (!confirm("Are you sure that you want to " + (cancel === 1 ? "cancel " : "refund ") + name + " " + id + " for user " + this.userId + "?")) {
                return;
            }

            this.$store.dispatch('androidRefund', {
                userid: this.userId,
                paymentid: id,
                infoTable: infoTable,
                cancel: cancel
            });

        },
        toggleProfile(state) {
            this.$store.commit('SET_UI_STATE', {
                sidebarCollapsed: this.$store.state.uiState.sidebarCollapsed,
                sidebarGameSelected: this.$store.state.uiState.sidebarGameSelected,
                userProfileCollapsed: state
            });

            setTimeout(function () {
                this.$refs.chat.adjustChatHeight();
            }.bind(this), 300);
        },
        tagSelected(option) {
            if (typeof option === 'string' && option.length > 0) {
                var action = 'addTag';
                var params = {
                    tag: option.substring(0, 20),
                    author: this.$store.state.respondingPerson,
                    userid: this.$route.params.userId,
                    dialogueid: this.$store.state.dialogueId,
                    game: this.$route.params.folder.split(':')[1]
                }

                this.$store.dispatch(action, params);
            }
        },
        removeTag(label) {
            this.$store.dispatch('removeTag', {
                userid: this.$route.params.userId,
                dialogueid: this.$store.state.dialogueId,
                tag: label,
                game: this.$route.params.folder.split(':')[1],
                fromUserCard: true
            });
        }
    }
}
</script>

<style scoped>

.after-sidebar {
    margin-left: 70px;
    height: 100%;
}

.tabs-container {
    overflow-y: auto;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    border-radius: 8px 8px 0 0;
    width: 98%;
    margin-left: 10px;
    margin-top: 4px;
    -webkit-transition: height .3s ease;
    transition: height .3s ease
}

.table-header-title {
    text-align: center;
    font-size: large;
}

.profile-block {
    display: inline-flex;
}

.collapse-icon {
    float: right;
    margin: 12px 20px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.expand-icon {
    float: right;
    margin: 12px 20px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.action-button {
    display: inline-block;
    border-radius: 6px;
    border: none;
    height: 24px;
    width: 116px;
    font-weight: bold;
}

a.action-button {
    margin-top: 14px;
    color: #5d91d0;
    cursor: pointer;
    font-family: Arial;
    text-align: center;
    text-decoration: none;
}

a.redButton {
    background-color: #004A7F;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Arial;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    -webkit-animation: glowing 1000ms infinite;
    -moz-animation: glowing 1000ms infinite;
    -o-animation: glowing 1000ms infinite;
    animation: glowing 1000ms infinite;
}

.dropdown-button {
    margin-left: 10px;
    margin-bottom: 4px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .15rem .75rem;
    font-size: 1rem;
    line-height: 1.3;
    border-radius: 4px;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.dropdown-button::after {
    display: inline-block;
    margin-left: .3em;
    vertical-align: .155em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 4px 12px;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin: 0px 4px;
}

.dropdown-item:hover {
    font-weight: 900;
}

.unselectable {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tags {
    margin: 11px;
    color: #5f5f5f;
    display: inline-block;
    float: right;
}

.tag {
    display: inline-block;
}

.tag-selection {
    position: relative;
    float: right;
    margin: 6px;
}

@-webkit-keyframes glowing {
    0% { background-color: #B20000; -webkit-box-shadow: 0 0 1px #B20000;}
    50% {background-color: #FF0000; -webkit-box-shadow: 0 0 15px #FF0000;}
    100% { background-color: #B20000; -webkit-box-shadow: 0 0 1px #B20000;}
}
@-moz-keyframes glowing {
    0% {background-color: #B20000;-moz-box-shadow: 0 0 1px #B20000;}
    50% {background-color: #FF0000;-moz-box-shadow: 0 0 15px #FF0000;}
    100% {background-color: #B20000;-moz-box-shadow: 0 0 1px #B20000;}
}
@-o-keyframes glowing {
    0% {background-color: #B20000;box-shadow: 0 0 1px #B20000;}
    50% {background-color: #FF0000;box-shadow: 0 0 15px #FF0000;}
    100% {background-color: #B20000;box-shadow: 0 0 1px #B20000;}
}
@keyframes glowing {
    0% {background-color: #B20000;box-shadow: 0 0 1px #B20000;}
    50% {background-color: #FF0000;box-shadow: 0 0 15px #FF0000;}
    100% {background-color: #B20000;box-shadow: 0 0 1px #B20000;}
}
</style>
